import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export default function Logos() {
  return (
    <div className="bg-white bg-opacity-25">
      <div className="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <h2 className="text- mx-auto max-w-lg text-center text-3xl font-semibold tracking-tight lg:max-w-3xl lg:text-left">
          Professional Memberships And Associations
        </h2>
        <div className="pt-20 lg:grid lg:grid-cols-1 lg:gap-8">
          <div className="mt-8 flow-root self-center lg:mt-0">
            <div className="-mt-4 -ml-8 flex flex-wrap justify-between lg:-ml-4">
              <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow justify-center lg:ml-4 lg:flex-grow-0">
                <StaticImage
                  src="../images/logos/CBBG.jpg"
                  quality={95}
                  height={188}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Canadian Bowenwork/Bowtech Group "
                />
              </div>
              <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow justify-center lg:ml-4 lg:flex-grow-0">
                <StaticImage
                  src="../images/logos/MSTR.jpg"
                  quality={95}
                  height={188}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="MSTR® McLoughlin Scar Tissue Release"
                />
              </div>

              <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow justify-center lg:ml-4 lg:flex-grow-0">
                <StaticImage
                  src="../images/logos/RNAO.jpg"
                  quality={95}
                  height={188}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Registered Nurses' Association of Ontario"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="pt-20 lg:grid lg:grid-cols-1 lg:gap-8">
          <div className="mt-8 flow-root self-center lg:mt-0">
            <div className="-mt-4 -ml-8 flex flex-wrap justify-between lg:-ml-4">
              <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow justify-center lg:ml-4 lg:flex-grow-0">
                <StaticImage
                  src="../images/logos/CNO.jpg"
                  quality={95}
                  height={188}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="College of Nurses of Ontario"
                />
              </div>
              <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow justify-center lg:ml-4 lg:flex-grow-0">
                <StaticImage
                  src="../images/logos/bowen-the-original.jpg"
                  quality={95}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="The Original Bowen Technique"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
