import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export default function Services() {
  return (
    <div className="overflow-hidden bg-gray-50 py-16  lg:py-24">
      <div className="relative mx-auto max-w-xl px-6 lg:max-w-7xl lg:px-8">
        <div className=" relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
          <div className="relative pr-5">
            <h3 className="text-2xl font-semibold tracking-tight text-gray-900 sm:text-3xl ">
              MSTR<span className="align-top text-xs">&reg;</span>
              <br /> McLoughlin Scar Tissue Release
            </h3>
            <p className="mt-3 text-lg text-gray-500">
              Is an innovative and hands on therapy for the treatment of scar
              tissue. The flow of lymph and circulation (energetic pathways) are
              interrupted in the healing of scars. It is a natural response for
              scar tissue to form collagen fibers that potentially cause the
              redirection of optimal circulation.
            </p>
            <p className="mt-3 text-lg text-gray-500">
              Scars may often have an emotional and psychological component
              associated with them, for example c-section scars or traumatic
              accident scars, surgical scars.
            </p>
            <p className="mt-3 text-lg text-gray-500">
              MSTR® also addresses scars below the surface, such as old
              fractures and muscle/tendon ligament injury .
            </p>
            <div className="py-6  ">
              <div className="sm:flex lg:col-span-7">
                <div className="mt-6 sm:mt-0 sm:ml-2">
                  <h3 className="text-lg font-medium text-gray-900">
                    MSTR Session
                  </h3>
                  <p className="mt-2 text-base font-medium text-gray-900">
                    Price: $70-$85
                  </p>
                  <p className="mt-3 text-sm text-gray-500">
                    Session length will depend on factors as assessed at time of
                    treatment (determined by length)
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="relative -mx-4 mt-10 lg:mt-0" aria-hidden="true">
            <StaticImage
              src="../images/massage-table.jpg"
              className="relative mx-auto"
              alt="Pure Bowen Therapy Massage Table"
            />
          </div>
        </div>

        <div className="relative mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:items-center lg:gap-8">
            <div className="lg:col-start-2 lg:pl-5">
              <h3 className="text-2xl font-semibold tracking-tight text-gray-900 sm:text-3xl">
                Bowen Therapy
              </h3>
              <p className="mt-3 text-lg text-gray-500">
                Bowen Therapy is a dynamic system of muscle and connective
                tissue therapy that creates subtle inputs to the body,
                stimulating the body to heal itself. This work offers tremendous
                benefit to patients and can provide relief for many types of
                injuries and health issues, both acute and chronic.
              </p>
              <div className="py-6  ">
                <div className="gap-x-20 sm:flex lg:col-span-7">
                  <div className="mt-6 sm:mt-0 sm:ml-2">
                    <h3 className="border-b border-brand-dark pb-2 text-base font-medium text-gray-900">
                      Initial consult and session
                      <p className="text-left text-sm font-medium uppercase text-gray-900 lg:text-center">
                        (90 mins)
                      </p>
                    </h3>

                    <p className="mt-4 text-lg font-medium text-gray-900">
                      Adults: $70
                    </p>
                    <p className="text-lg font-medium text-gray-900">
                      Children(Under 18): $30
                    </p>
                  </div>
                  <div className="mt-6 sm:mt-0 sm:ml-2">
                    <h3 className="border-b border-brand-dark pb-2 text-base font-medium text-gray-900">
                      Subsequent Treatments
                      <p className="text-left text-sm font-medium uppercase text-gray-900 lg:text-center">
                        (45 - 60 mins)
                      </p>
                    </h3>
                    <p className="mt-4 text-lg font-medium text-gray-900">
                      Adults: $70
                    </p>
                    <p className="text-lg font-medium text-gray-900">
                      Children(Under 18): $30
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative -mx-4 mt-10 lg:col-start-1 lg:mt-0">
              <StaticImage
                src="../images/massage.jpg"
                className="relative mx-auto"
                alt="Massage Technique"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
