import * as React from 'react';
import About from '../components/about';

import Contact from '../components/contact';
import Description from '../components/description';
import Features from '../components/features';
import Layout from '../components/layout';
import Logos from '../components/logo';
import Seo from '../components/seo';
import Services from '../components/services';
import Testimonials from '../components/testimonials';

function Index() {
  return (
    <Layout>
      <Seo title="Pure Bowen Therapy" />

      <Features />

      <Description />
      <About />
      <Services />
      <Testimonials />

      <Logos />
      <Contact />
    </Layout>
  );
}

export default Index;
