import React from 'react';
import {
  PhoneIcon,
  EnvelopeIcon,
  MapPinIcon,
} from '@heroicons/react/24/outline';
import useForm from '../utils/useForm';
import useQuote from '../utils/useQuote';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Contact() {
  const { values, updateValue } = useForm({
    name: '',
    email: '',
    phone: '',
    messageText: '',
    mapleSyrup: '',
  });
  const { error, loading, message, submitForm } = useQuote({ values });

  if (message) {
    return <p>{message}</p>;
  }
  return (
    <div className="relative w-full" id="contact">
      <div className="bg-gray-100">
        <div className="  mx-auto">
          <div className=" relative bg-white shadow-xl">
            <div className="grid grid-cols-1 lg:grid-cols-3">
              {/* Contact information */}
              <div className="relative overflow-hidden bg-brand-green-dark py-10 px-6 sm:px-10 xl:p-12">
                <div
                  className="pointer-events-none absolute inset-0 sm:hidden"
                  aria-hidden="true"
                >
                  <svg
                    className="absolute inset-0 h-full w-full"
                    width={343}
                    height={388}
                    viewBox="0 0 343 388"
                    fill="none"
                    preserveAspectRatio="xMidYMid slice"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                      fill="url(#linear1)"
                      fillOpacity=".1"
                    />
                    <defs>
                      <linearGradient
                        id="linear1"
                        x1="254.553"
                        y1="107.554"
                        x2="961.66"
                        y2="814.66"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#fff" />
                        <stop offset={1} stopColor="#fff" stopOpacity={0} />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div
                  className=" pointer-events-none absolute top-0 right-0 bottom-0 hidden w-1/2 sm:block lg:hidden"
                  aria-hidden="true"
                >
                  <svg
                    className="absolute inset-0 h-full w-full"
                    width={359}
                    height={339}
                    viewBox="0 0 359 339"
                    fill="none"
                    preserveAspectRatio="xMidYMid slice"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                      fill="url(#linear2)"
                      fillOpacity=".1"
                    />
                    <defs>
                      <linearGradient
                        id="linear2"
                        x1="192.553"
                        y1="28.553"
                        x2="899.66"
                        y2="735.66"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#fff" />
                        <stop offset={1} stopColor="#fff" stopOpacity={0} />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div
                  className="pointer-events-none absolute top-0 right-0 bottom-0 hidden w-1/2 lg:block"
                  aria-hidden="true"
                >
                  <svg
                    className="absolute inset-0 h-full w-full"
                    width={160}
                    height={678}
                    viewBox="0 0 160 678"
                    fill="none"
                    preserveAspectRatio="xMidYMid slice"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                      fill="url(#linear3)"
                      fillOpacity=".1"
                    />
                    <defs>
                      <linearGradient
                        id="linear3"
                        x1="192.553"
                        y1="325.553"
                        x2="899.66"
                        y2="1032.66"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#fff" />
                        <stop offset={1} stopColor="#fff" stopOpacity={0} />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>

                <div className="mx-auto flex w-full justify-start sm:w-11/12 lg:justify-end">
                  <dl className="space-y-6">
                    <h3 className="text-2xl font-semibold text-white sm:text-lg">
                      Contact information
                    </h3>
                    <dt>
                      <span className="sr-only">Phone number</span>
                    </dt>
                    <dd className="flex text-xl text-gray-50">
                      <PhoneIcon
                        className="h-6 w-6 flex-shrink-0 text-gray-200"
                        aria-hidden="true"
                      />
                      <span className="ml-3">519-319-1198</span>
                    </dd>
                    <dt>
                      <span className="sr-only">Email</span>
                    </dt>
                    <dd className="flex text-lg text-gray-50">
                      <EnvelopeIcon
                        className="h-6 w-6 flex-shrink-0 text-gray-200"
                        aria-hidden="true"
                      />
                      <a href="mailto:info@purebowentherapy.com">
                        <span className="ml-3">info@purebowentherapy.com</span>
                      </a>
                    </dd>
                    <dd className="flex text-xl text-gray-50">
                      <MapPinIcon
                        className="h-6 w-6 flex-shrink-0 text-gray-200"
                        aria-hidden="true"
                      />
                      <div className="flex flex-col">
                        <span className="ml-3">44287 Truman Line</span>
                        <span className="ml-3"> Belmont ON N0L 1B0 </span>
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>

              {/* Contact form */}
              <div className=" border-t-2 py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
                {/* <div className="lg:pl-32"> */}
                <div className="lg:pl-32">
                  <div className="mx-auto max-w-md sm:max-w-lg lg:mx-0">
                    <h2 className="text-3xl font-semibold tracking-tight text-gray-900">
                      Let's connect!
                    </h2>
                    <p className="mt-4 text-lg  sm:mt-3">
                      Send us a message or call us. We would love to help!
                    </p>
                    <form
                      action="#"
                      method="POST"
                      onSubmit={submitForm}
                      className="mt-9 grid grid-cols-1 gap-y-2 sm:grid-cols-2"
                    >
                      <div className="sm:col-span-2">
                        <div className="mt-1">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Name"
                            value={values.name}
                            onChange={updateValue}
                            autoComplete="given-name"
                            className={classNames(
                              error
                                ? 'border-2 border-red-500 ring-red-500'
                                : 'border-2 border-gray-300',
                              'block w-full rounded-md  px-5  py-3 shadow-sm sm:text-sm'
                            )}
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <div className="flex justify-between" />
                        <div className="mt-1">
                          <input
                            type="text"
                            name="phone"
                            id="phone"
                            value={values.phone}
                            placeholder="Phone"
                            onChange={updateValue}
                            autoComplete="tel"
                            aria-describedby="phone-description"
                            className="block w-full rounded-md  border-2 border-gray-300 px-5 py-3 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <div className="mt-1">
                          <input
                            id="email"
                            name="email"
                            type="email"
                            placeholder="Email Address"
                            value={values.email}
                            onChange={updateValue}
                            autoComplete="email"
                            className={classNames(
                              error
                                ? 'border-2 border-red-500 ring-red-500'
                                : 'border-2 border-gray-300',
                              'block w-full  rounded-md  px-5 py-3 shadow-sm sm:text-sm'
                            )}
                          />
                          <input
                            id="mapleSyrup"
                            name="mapleSyrup"
                            type="text"
                            value={values.mapleSyrup}
                            onChange={updateValue}
                            className="hidden"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-2">
                        <div className="mt-1">
                          <textarea
                            id="messageText"
                            name="messageText"
                            value={values.messageText}
                            onChange={updateValue}
                            placeholder="Message"
                            aria-describedby="how-can-we-help-description"
                            rows={4}
                            className="block w-full  rounded-md border border-gray-300 px-5 py-3 shadow-sm focus:border-gray-500  focus:ring-gray-500 sm:text-sm"
                          />
                        </div>

                        {error ? (
                          <div className="mt-10 border-2 border-dotted border-red-500  bg-gray-50 p-3 text-center text-red-500">
                            <p>Error: {error} </p>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>

                      <div className="text-right sm:col-span-2">
                        <button
                          type="submit"
                          disabled={loading}
                          className="mt-10  inline-flex w-full justify-center border  border-transparent bg-brand-green-light py-4 px-4 text-lg font-semibold uppercase text-white  shadow-sm hover:bg-brand-green-dark hover:text-brand-gold focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                        >
                          {loading ? 'Sending' : 'Contact Us'}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
