import { CheckIcon } from '@heroicons/react/24/outline';
import React from 'react';

const features = [
  'Back pain, sciatica',
  'Digestive and bowel problems, IBS',
  'Migraines',
  'Fibromyalgia, CFS',
  'Infant colic',
  'Knee. ankle, and foot problems',
  'Menstrual and hormonal irregularities',
  'Infertility',
  'Sports and accident injuries',
  'Neck/shoulder problems, frozen shoulder',
  'Bedwetting',
  'Groin pain and pelvic tilt',
  'Prostate problems',
  'Respiratory problems',
  'Carpal tunnel syndrome, RSI',
  'Tennis or golf elbow',
  'PTSD, depression',
];

export default function Description() {
  return (
    <div className="relative bg-white">
      <div className="absolute inset-0 h-1/2 bg-gray-100 bg-gradient-to-b from-gray-200 to-white" />
      <div className="relative mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className=" xl:grid xl:grid-cols-3 xl:gap-x-8">
          <div>
            <p className="mb-4 text-lg text-gray-500">
              Bowen Therapy is a dynamic system of muscle and connective tissue
              therapy that creates subtle inputs to the body, stimulating the
              body to heal itself. This work offers tremendous benefit to
              patients and can provide relief for many types of injuries and
              health issues, both acute and chronic.
            </p>
            <h2 className="text-2xl font-semibold text-brand-green-dark">
              The indications for use include:
            </h2>
            <p className="mt-1 text-xl font-semibold tracking-tight text-gray-900">
              and many more...
            </p>
          </div>
          <div className="mt-4 sm:mt-8 md:mt-10 md:grid md:grid-cols-2 md:gap-x-8 xl:col-span-2 xl:mt-0">
            <ul role="list" className="divide-y divide-gray-200">
              {features.slice(0, 12).map((feature, featureIdx) =>
                featureIdx === 0 ? (
                  <li key={feature} className="flex py-4 md:py-0 md:pb-4">
                    <CheckIcon
                      className="h-6 w-6 flex-shrink-0 text-brand-green-light"
                      aria-hidden="true"
                    />
                    <span className="ml-3 text-base text-gray-500">
                      {feature}
                    </span>
                  </li>
                ) : (
                  <li key={feature} className="flex py-4">
                    <CheckIcon
                      className="h-6 w-6 flex-shrink-0 text-brand-green-light"
                      aria-hidden="true"
                    />
                    <span className="ml-3 text-base text-gray-500">
                      {feature}
                    </span>
                  </li>
                )
              )}
            </ul>
            <ul
              role="list"
              className="divide-y divide-gray-200 border-t border-gray-200 md:border-t-0"
            >
              {features.slice(5).map((feature, featureIdx) =>
                featureIdx === 0 ? (
                  <li
                    key={feature}
                    className="flex py-4 md:border-t-0 md:py-0 md:pb-4"
                  >
                    <CheckIcon
                      className="h-6 w-6 flex-shrink-0 text-green-500"
                      aria-hidden="true"
                    />
                    <span className="ml-3 text-base text-gray-500">
                      {feature}
                    </span>
                  </li>
                ) : (
                  <li key={feature} className="flex py-4">
                    <CheckIcon
                      className="h-6 w-6 flex-shrink-0 text-green-500"
                      aria-hidden="true"
                    />
                    <span className="ml-3 text-base text-gray-500">
                      {feature}
                    </span>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
