import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';

const quotes = [
  {
    id: '1',
    text: 'Bowen therapy is the most relaxing experience with many physical and mental benefits. The quiet room and miniature physical movements puts me into deep relaxation, almost falling asleep. The therapy promotes periodic positive sensations throughout. These sensations can return days afterwards. Bowen therapy is a great investment in my self care. My body responds well to the treatment provided by Leigh.',
    name: 'Kim Gould',
  },
  {
    id: '2',
    text: 'I was diagnosed with cervical radiculopathy a few years ago (pinched nerve in my neck). It was causing tingling and "pins and needles” in my wrist down into my thumb. I booked appointments with Leigh Mauer, Registered Bowen Therapist to see if Bowen Therapy would help relieve the problem. After some treatments, the tingling and pins and needles resolved. I will certainly be contacting Leigh if the tingling/pins and needles start up again, or I have any other issues. I had never heard of Bowen Therapy, but Leigh is very professional and explained everything as she went along in terms that you can understand. I highly suggest Bowen Therapy for "anything that ails/pains you". You’ll be surprised how much it helps.',
    name: 'Sandy',
  },
  {
    id: '3',
    text: "I will forever be grateful for Leigh and the Bowen therapy that she was able to do on my lower back/sciatic area! The relief I felt was so incredible that I was able to finally get a good night sleep. With Leigh's gentle approach  and warm and calming demeanour, I feel relaxed and at ease the second I walk into her treatment room, and know I am in amazingly capable and healing hands. Thank you so much Leigh!!",
    name: 'Shannon',
  },
];

const Testimonials = function () {
  SwiperCore.use([Autoplay]);

  return (
    <div
      className="relative  bg-brand-green-light text-white"
      id="testimonials"
    >
      <div className="mx-auto max-w-7xl py-20">
        <p className="pb-10 text-center text-3xl font-semibold">
          What Others Are Saying
        </p>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          autoplay={{ delay: 8000 }}
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log('slide change')}
        >
          {quotes.map((testimonial) => (
            <SwiperSlide key={testimonial.id}>
              <div className="flex flex-col items-center justify-center">
                <div className="w-4/5  text-xl lg:w-3/5">
                  {testimonial.text}
                  <div className="text-right">~ {testimonial.name}</div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
export default Testimonials;
