import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function About() {
  return (
    <div className="bg-gradient-to-r from-brand-green-dark to-brand-green-light ">
      <div className="mx-auto py-16 px-4 sm:px-6 md:max-w-2xl md:px-2 lg:max-w-5xl lg:py-12 lg:px-8 xl:max-w-7xl">
        <div className=" flex flex-col lg:grid lg:grid-cols-3 lg:gap-8">
          <div className=" mt-10 lg:col-span-2 lg:mt-0">
            <h2 className="text-3xl font-thin tracking-tight text-white">
              Leigh Mauer, RN
            </h2>
            <h3 className="pb-4 text-base tracking-tight text-white">
              Bowtech
              <span className="align-top text-xs">&reg;</span> Practitioner,
              MSTR<span className="align-top text-xs">&reg;</span> Practitioner
            </h3>
            <p className="pb-4 text-xl font-normal text-white">
              As a registered nurse for over 30 years, most of my career in long
              term care, I was witness to people suffering in pain.
            </p>
            <p className="pb-4 text-xl font-normal text-white">
              In 2019 I embarked on Bowen Therapy certification and subsequently
              completed the course in 2020. While traditional medicine plays a
              valid role in pain recovery, innately our bodies can heal itself.
            </p>
            <p className="pb-4 text-xl font-normal text-white">
              The signal that is sent through the nervous system from the gentle
              hands-on input of the technique elicits a response to balance and
              adjust, conditioning the body to promote healing.
            </p>
          </div>
          <div className="order-first lg:order-last">
            <StaticImage
              src="../images/leigh.png"
              className="mr-auto flex w-1/2 rounded-full object-cover sm:w-2/5 md:w-2/5 lg:mx-auto lg:w-3/4"
              alt="Leigh Mauer"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
